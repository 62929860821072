import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  LoginPT,
  LoginEN,
  LoginES,
  ForgotPT,
  ForgotEN,
  ForgotES,
} from "./LoginPage/i18n";
import { KanbanPT, KanbanEN, KanbanES } from "./KanbanPage/i18n";
import { BoardPT, BoardEN, BoardES } from "./BoardPage/i18n";
import { HeaderEN, HeaderES, HeaderPT } from "./Global/Header/i18n";
import { TablesEN, TablesES, TablesPT } from "./Tables/i18n";

import bundlePT from "../../../global/i18n/ptBundles";
import bundleEN from "../../../global/i18n/enBundles";
import bundleES from "../../../global/i18n/esBundles";
import {
  MessagesEN,
  MessagesES,
  MessagesPT,
} from "./SettingsPage/Messages/i18n";
import { WidgetsEN, WidgetsES, WidgetsPT } from "./SettingsPage/Widgets/i18n";
import { GlobalsEN, GlobalsES, GlobalsPT } from "./Global/i18n";
import { SettingsMenuPT, SettingsMenuEN, SettingsMenuES } from "./SettingsPage/i18n";


i18next.use(LanguageDetector).init({
  debug: true,
  fallbackLng: "pt",
  resources: {
    pt: {
      translation: {
        // -----tradução do menu -----
        "$screen.fisdash": "Dashboard",
        "$screen.mod.security": "Segurança",
        "$screen.mod.admin": "Administração",
        "$screen.config": "Configuração de Sistema",
        "$screen.grupoacesso": "Grupo de Acesso",
        "$screen.usuarios": "Usuário",
        "$screen.module": "Módulo",
        "$screen.connection": "Conexão Externa",
        "$screen.credentials": "Credenciais",
        "$screen.migration": "Migração de Dados",

        "$screen.mod.cadastrosgerais": "Cadastros",
        "$screen.status": "Estado",
        "$screen.uo": "Unidade Organizacional",
        "$label.ent.seproperty": "Propriedade",
        "$screen.mod.entity": "Entidade",
        "$screen.entext": "Entidade Externa",
        "$screen.mod.busform": "Processo de Negócio",
        "$screen.bpm": "Diagrama BPM",
        "$screen.view": "Visão",
        "$screen.widgets": "Widget",
        "$screen.categbusform": "Categoria",
        "$screen.pages": "Páginas",

        "$screen.mod.ppmproject": "Projetos",
        "$screen.projecttype": "Tipo de Projeto",
        "$screen.tasktype": "Tipo de Tarefa",
        "$screen.projects": "Projetos",
        "$screen.tasks": "Tarefas",
        "$screen.projectmpp": "MS Project - MPP",
        "$screen.ppmconfig": "Configurações",

        "$mod.integration": "iPaaS - Integrações",
        "$screen.monitor": "Monitor",

        "$screen.mod.rpa": "Robotização (RPA)",
        "$screen.bots": "Criação de Bots",
        "$screen.botspub": "Bots Publicados",
        "$screen.botsqueue": "Painel de Controle",
        "$screen.devqueue": "Logs de Criação",
        "$screen.devices": "Dispositivos",

        "$screen.mod.chatbot": "ChatBot",
        "$screen.contato": "Contato",
        "$screen.fluxo": "Fluxo",
        "$screen.canal": "Canal",

        "$screen.mod.fiscal": "Docs. Fiscais",
        "$screen.fismonitor": "Monitor",
        "$screen.danfeempresa": "DANFE por Empresa",
        "$screen.danfensu": "DANFEs por NSU",

        "$screen.mod.cnd": "Gestão de certidões",
        "$screen.cndcnpjs": "Lista de cnpj's",
        "$screen.cndmonitor": "Monitor de certidões",

        "$label.ent.entities": "Entidades",
        // -------------------------------


        login: LoginPT,
        forgotPassword: ForgotPT,
        header: HeaderPT,
        kanban: KanbanPT,
        tables: TablesPT,
        messages: MessagesPT,
        widgets: WidgetsPT,
        globals: GlobalsPT,
        boards: BoardPT,
        settings: SettingsMenuPT,
      },
    },
    en: {
      translation: {
        // -----menu translation -----
        "$screen.fisdash": "Dashboard",
        "$screen.mod.security": "Security",
        "$screen.mod.admin": "Administration",
        "$screen.config": "System Configuration",
        "$screen.grupoacesso": "Access Group",
        "$screen.usuarios": "User",
        "$screen.module": "Module",
        "$screen.connection": "External Connection",
        "$screen.credentials": "Credentials",
        "$screen.migration": "Data Migration",

        "$screen.mod.cadastrosgerais": "Registrations",
        "$screen.status": "Status",
        "$screen.uo": "Organizational Unit",
        "$label.ent.seproperty": "Property",
        "$screen.mod.entity": "Entity",
        "$screen.entext": "External Entity",
        "$screen.mod.busform": "Business Process",
        "$screen.bpm": "BPM Diagram",
        "$screen.view": "View",
        "$screen.widgets": "Widget",
        "$screen.categbusform": "Category",
        "$screen.pages": "Pages",

        "$screen.mod.ppmproject": "Projects",
        "$screen.projecttype": "Project Type",
        "$screen.tasktype": "Task Type",
        "$screen.projects": "Projects",
        "$screen.tasks": "Tasks",
        "$screen.projectmpp": "MS Project - MPP",
        "$screen.ppmconfig": "Configurations",

        "$mod.integration": "iPaaS - Integrations",
        "$screen.monitor": "Monitor",

        "$screen.mod.rpa": "Robotics (RPA)",
        "$screen.bots": "Bot Creation",
        "$screen.botspub": "Published Bots",
        "$screen.botsqueue": "Control Panel",
        "$screen.devqueue": "Creation Logs",
        "$screen.devices": "Devices",

        "$screen.mod.chatbot": "ChatBot",
        "$screen.canal": "Channel",
        "$screen.contato": "Contact",
        "$screen.fluxo": "Flow",

        "$screen.mod.fiscal": "Fiscal Documents",
        "$screen.fismonitor": "Monitor",
        "$screen.danfeempresa": "DANFE by Company",
        "$screen.danfensu": "DANFEs by NSU",

        "$screen.mod.cnd": "Certificate Management",
        "$screen.cndcnpjs": "CNPJ List",
        "$screen.cndmonitor": "Certificate Monitor",

        "$label.ent.entities": "Entities",
        // -------------------------------


        login: LoginEN,
        forgotPassword: ForgotEN,
        header: HeaderEN,
        kanban: KanbanEN,
        tables: TablesEN,
        messages: MessagesEN,
        widgets: WidgetsEN,
        globals: GlobalsEN,
        boards: BoardEN,
        settings: SettingsMenuEN
      },
    },
    es: {
      translation: {

        // -----menu translation -----
        "$screen.fisdash": "Dashboard",
        "$screen.mod.security": "Seguridad",
        "$screen.mod.admin": "Administración",
        "$screen.config": "Configuración del Sistema",
        "$screen.grupoacesso": "Grupo de Acceso",
        "$screen.usuarios": "Usuario",
        "$screen.module": "Módulo",
        "$screen.connection": "Conexión Externa",
        "$screen.credentials": "Credenciales",
        "$screen.migration": "Migración de Datos",

        "$screen.mod.cadastrosgerais": "Registros",
        "$screen.status": "Estado",
        "$screen.uo": "Unidad Organizacional",
        "$label.ent.seproperty": "Propiedad",
        "$screen.mod.entity": "Entidad",
        "$screen.entext": "Entidad Externa",
        "$screen.mod.busform": "Proceso de Negocio",
        "$screen.bpm": "Diagrama BPM",
        "$screen.view": "Vista",
        "$screen.widgets": "Widget",
        "$screen.categbusform": "Categoría",
        "$screen.pages": "Páginas",

        "$screen.mod.ppmproject": "Proyectos",
        "$screen.projecttype": "Tipo de Proyecto",
        "$screen.tasktype": "Tipo de Tarea",
        "$screen.projects": "Proyectos",
        "$screen.tasks": "Tareas",
        "$screen.projectmpp": "MS Project - MPP",
        "$screen.ppmconfig": "Configuraciones",

        "$mod.integration": "iPaaS - Integraciones",
        "$screen.monitor": "Monitor",

        "$screen.mod.rpa": "Robotización (RPA)",
        "$screen.bots": "Creación de Bots",
        "$screen.botspub": "Bots Publicados",
        "$screen.botsqueue": "Panel de Control",
        "$screen.devqueue": "Registros de Creación",
        "$screen.devices": "Dispositivos",

        "$screen.mod.chatbot": "ChatBot",
        "$screen.canal": "Canal",
        "$screen.contato": "Contacto",
        "$screen.fluxo": "Flujo",

        "$screen.mod.fiscal": "Documentos Fiscales",
        "$screen.fismonitor": "Monitor",
        "$screen.danfeempresa": "DANFE por Empresa",
        "$screen.danfensu": "DANFEs por NSU",

        "$screen.mod.cnd": "Gestión de Certificados",
        "$screen.cndcnpjs": "Lista de CNPJ",
        "$screen.cndmonitor": "Monitor de Certificados",

        "$label.ent.entities": "Entidades",
        // -------------------------------


        login: LoginES,
        forgotPassword: ForgotES,
        header: HeaderES,
        kanban: KanbanES,
        tables: TablesES,
        messages: MessagesES,
        widgets: WidgetsES,
        globals: GlobalsES,
        boards: BoardES,
        settings: SettingsMenuES
      },
    },
  },
});

for (const ns in bundlePT) {
  i18next.addResourceBundle("pt", ns, bundlePT[ns], true, true);
}
for (const ns in bundleEN) {
  i18next.addResourceBundle("en", ns, bundleEN[ns], true, true);
}
for (const ns in bundleES) {
  i18next.addResourceBundle("es", ns, bundleES[ns], true, true);
}

export default function (app) {
  app.use(I18NextVue, { i18next });
  return app;
}
