import { defineStore } from "pinia";
import { apiDev } from "@/services/api";
import { showPositiveNotify, showNegativeNotify } from "@/utils/useNotify";

export const useAccessGroup = defineStore("accessGroup", {
  state: () => ({
    isCreate: false,
    groups: [],
    permissions: [],
    usersGroup: [],
    screens: [],
    loading: false,
    loadedScreens: false,
    loadedUsers: false,
    formLoaded: false,
    textSearch: null,
    selectActive: { label: "Apenas Ativos", value: "Y" },
    pagination: {
      page: 1,
      rowsPerPage: 50,
      rowsNumber: 0,
      sortBy: "nomeGrupo",
      descending: false,
    },
    formData: {
      id: null,
      groupName: "",
      groupDescription: "",
      isActive: "Y",
      creationDate: "",
      addedUsers: [],
      removedUsers: [],
      permissions: [],
      deletedPermScreen: [],
      existingUsers: [],
    },
    rpaPermissions: [],
    securityPermissions: [],
    adminPermissions: [],
    localUsers: [],
  }),

  actions: {
    async getAllGroups() {
      this.loading = true;
      try {
        const orderDirection = this.pagination.descending ? "desc" : "asc";
        const searchValue = this.textSearch?.trim() || "";
        const queries = `page=${this.pagination.page}&perPage=${this.pagination.rowsPerPage}&order=${this.pagination.sortBy}_${orderDirection}&nomeGrupo=${searchValue}&description=${searchValue}&isActive=${this.selectActive.value}`;

        const res = await apiDev.get(`/adm/v2/cas/agrp?${queries}`);
        if (res.data && res.data.success) {
          this.groups = res.data.data.rows;
          this.pagination.rowsNumber = res.data.data.totalRows;
        } else {
          console.error("Erro ao carregar grupos:", res.data.message);
        }
      } catch (error) {
        console.error("Erro na requisição de grupos:", error);
      } finally {
        this.loading = false;
      }
    },

    setPagination(pagination) {
      Object.assign(this.pagination, pagination);
      this.getAllGroups();
    },

    async getGroupById(id) {
      if (this.formLoaded) return this.formData;

      this.loading = true;
      try {
        const res = await apiDev.get(`/adm/v2/cas/agrp/${id}`);
        if (res.data && res.data.success) {
          const group = res.data.data;
          this.formData = {
            id: group.id,
            groupName: group.nomeGrupo,
            groupDescription: group.description,
            isActive: group.active,
            creationDate: group.createdAt || "",
            addedUsers: [],
            removedUsers: [],
            permissions: group.grupoPermissoes || [],
            deletedPermScreen: [],
            existingUsers: group.grupoUsuarios.map(user => ({
              id: user.user.id,
              name: user.user.name,
              isActive: user.user.isActive,
            })) || [],
          };
          this.localUsers = this.formData.existingUsers.map(user => ({
            ...user,
            status: user.isActive === "Y",
          }));
          this.formLoaded = true;
          return this.formData;
        }
      } catch (error) {
        console.error("Erro ao buscar grupo:", error);
      } finally {
        this.loading = false;
      }
    },

    async getUsers(search = "") {
      this.loading = true;
      try {
        const queries = `page=1&perPage=200&&name=${search.trim()}`;
        const res = await apiDev.get(`/adm/v2/sec/user?${queries}`);

        if (res.data && res.data.success) {
          this.usersGroup = res.data.data.rows;
          this.pagination.rowsNumber = res.data.data.totalRows;
          this.loadedUsers = true;
        } else {
          console.error("Erro ao carregar usuários:", res.data.message);
        }
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      } finally {
        this.loading = false;
      }
    },

    addUserToGroup(user) {
      const exists = this.localUsers.some(localUser => localUser.id === user.id);
      if (!exists) {
        this.localUsers.push({ ...user, isNew: true, deleted: false });
      } else {
        const existingUser = this.localUsers.find(u => u.id === user.id);
        if (existingUser.deleted) {
          existingUser.deleted = false;
          existingUser.isNew = true;
        }
      }
    },

    removeUserFromGroup(userId) {
      const user = this.localUsers.find(u => u.id === userId);
      if (user) {
        user.deleted = true;
        user.isNew = false;
      }
    },

    restoreUser(userId) {
      const user = this.localUsers.find(u => u.id === userId);
      if (user) {
        user.deleted = false;
        user.isNew = true;
      }
    },

    resetScreensLoaded() {
      this.loadedScreens = false;
    },

    async getScreens() {
      if (this.loadedScreens) return;
    
      try {
        const res = await apiDev.get("/adm/v2/cas/screen");
        this.screens = Array.isArray(res.data.data.rows) ? res.data.data.rows : [];
    
        const mapPermissions = (screen) => ({
          telaId: screen.id,
          title: screen.title,
          add: false,
          query: false,
          edit: false,
          delete: false,
        });
    
        this.rpaPermissions = this.screens
          .filter(screen => screen.module.key === "mod-rpa")
          .map(mapPermissions);
    
        this.securityPermissions = this.screens
          .filter(screen => screen.module.key === "mod-security")
          .map(mapPermissions);
    
        this.adminPermissions = this.screens
          .filter(screen => screen.module.key === "mod-admin")
          .map(mapPermissions);
    
        this.loadedScreens = true;
      } catch (error) {
        console.error("Erro ao buscar telas:", error);
      }
    },    

    updatePermissions(permissions, moduleKey) {
      const mapFlagsToFrontend = (permission) => ({
        telaId: permission.telaId,
        add: permission.flagInclusao?.toUpperCase() === "Y",
        query: permission.flagConsulta?.toUpperCase() === "Y",
        edit: permission.flagAlteracao?.toUpperCase() === "Y",
        delete: permission.flagExclusao?.toUpperCase() === "Y",
      });
    
      const mappedPermissions = permissions.map(mapFlagsToFrontend);
    
      if (moduleKey === "mod-rpa") {
        this.rpaPermissions = [...mappedPermissions];
      } else if (moduleKey === "mod-security") {
        this.securityPermissions = [...mappedPermissions];
      } else if (moduleKey === "mod-admin") {
        this.adminPermissions = [...mappedPermissions];
      }
    },      

    updateGroupInfo(data) {
      Object.assign(this.formData, data);
    },

    resetFormData() {
      this.formData = {
        id: null,
        groupName: "",
        groupDescription: "",
        isActive: "Y",
        creationDate: "",
        addedUsers: [],
        removedUsers: [],
        permissions: [],
        deletedPermScreen: [],
        existingUsers: [],
      };
      this.groups = [];
      this.permissions = [];
      this.usersGroup = [];
      this.screens = [];
      this.localUsers = [];
      this.rpaPermissions = [];
      this.securityPermissions = [];
      this.adminPermissions = [];
      this.loading = false;
      this.loadedScreens = false;
      this.loadedUsers = false;
      this.formLoaded = false;
      this.textSearch = null;
      this.selectActive = { label: "Apenas Ativos", value: "Y" };
      this.pagination = {
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
        sortBy: "nomeGrupo",
        descending: false,
      };
    },    

    async saveGroup() {
      if (!this.formData.groupName?.trim()) {
        return showNegativeNotify("O campo 'NOME DO GRUPO' é obrigatório!");
      }

      this.updateUserLists();

      this.loading = true;
      try {
        const groupData = this.buildGroupData();
        const response = await apiDev.post("/adm/v2/cas/agrp", groupData);

        if (response.data.success) {
          showPositiveNotify("Grupo de Acesso criado com sucesso!");
          return true;
        } else {
          throw new Error("Erro ao criar grupo de acesso");
        }
      } catch (error) {
        console.error("Erro ao criar grupo de acesso", error);
        showNegativeNotify("Erro ao criar grupo de acesso!");
        return false;
      } finally {
        this.loading = false;
      }
    },

    async updateGroup() {
      if (!this.formData.groupName?.trim()) {
        return showNegativeNotify("O campo 'NOME DO GRUPO' é obrigatório!");
      }

      this.updateUserLists();

      this.loading = true;
      try {
        const groupData = this.buildGroupData();
        const response = await apiDev.put(`/adm/v2/cas/agrp/${this.formData.id}`, groupData);

        if (response.data.success) {
          showPositiveNotify("Grupo de Acesso atualizado com sucesso!");
          return true;
        } else {
          throw new Error("Erro ao atualizar grupo de acesso");
        }
      } catch (error) {
        console.error("Erro ao atualizar grupo de acesso", error);
        showNegativeNotify("Erro ao atualizar grupo de acesso!");
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateUserLists() {
      const existingUserIds = new Set(this.formData.existingUsers.map(user => user.id));

      this.formData.addedUsers = this.localUsers
        .filter(user => user.isNew && !user.deleted && !existingUserIds.has(user.id))
        .map(user => user.id);

      this.formData.removedUsers = this.localUsers
        .filter(user => user.deleted && existingUserIds.has(user.id))
        .map(user => user.id);
    },

    buildGroupData() {
      return {
        nomeGrupo: this.formData.groupName?.trim(),
        active: this.formData.isActive,
        description: this.formData.groupDescription?.trim(),
        newUsers: this.formData.addedUsers,
        removedUsers: this.formData.removedUsers,
        newPermissions: [...this.rpaPermissions, ...this.securityPermissions, ...this.adminPermissions].map(p => ({
          telaId: p.telaId,
          flagConsulta: p.query ? "Y" : "N",
          flagInclusao: p.add ? "Y" : "N",
          flagAlteracao: p.edit ? "Y" : "N",
          flagExclusao: p.delete ? "Y" : "N",
        })),
        deletedPermScreen: this.formData.deletedPermScreen,
      };
    },
  },
});
